import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { humanSizeInKb, humanSizeInPixels } from '../../utils/size.utils'

import CompressionGroup from './CompressionGroup'
import CompressionCard from './CompressionCard'
import CommonSettingsBar from './CommonSettingsBar'
import PostInfo from './PostInfo'

import {
  createCompressionsRequest,
  selectAllAvailableImages,
  emptyIdsToCompress,
  toggleIdToCompress,
} from '../../actions/compression'

const mapStateToProps = state => {
  const { idsToCompress, availableImages } = state.compression
  return { idsToCompress, availableImages }
}

const mapDispatchToProps = {
  createCompressionsRequest,
  selectAllAvailableImages,
  emptyIdsToCompress,
  toggleIdToCompress,
}

class AvailableToCompress extends Component {
  renderItem = (image) => {
    const { idsToCompress, toggleIdToCompress } = this.props

    return (
      <CompressionCard
        key={image.id}
        original={image}
        onChange={() => this.props.toggleIdToCompress(image.id)}
        checked={idsToCompress.includes(image.id)}
        selectable={true}
        showMenu={true}
      >
        <PostInfo url={image.post_url} title={image.post_title}/>
        {[
          humanSizeInPixels(image.width, image.height),
          humanSizeInKb(image.size),
        ].filter(i => i).join(', ')}
      </CompressionCard>
    )
  }

  renderButtons = () => {
    const { idsToCompress, emptyIdsToCompress, selectAllAvailableImages, createCompressionsRequest } = this.props

    return (
      <Fragment>
        <button type='button' className='btn btn-sm btn-secondary' onClick={selectAllAvailableImages}>Select All</button>&nbsp;
        <button type='button' className='btn btn-sm btn-secondary' onClick={emptyIdsToCompress}>Unselect All</button>&nbsp;
        {idsToCompress.length > 0 &&
        <Fragment>
          <button type='button' className='btn btn-sm btn-success' onClick={createCompressionsRequest}>Compress selected</button>&nbsp;
        </Fragment>}
      </Fragment>
    )
  }

  render = () => {
    const { availableImages } = this.props
    if (availableImages.length === 0) return null

    return (
      <CompressionGroup
        caption='Available to compress'
        buttons={this.renderButtons()}
        className='compression-group-available'
      >
        <div className='col-12'>
          <CommonSettingsBar />
          <hr/>
        </div>
        {availableImages.sort((a, b) => a.size < b.size).map(this.renderItem)}
      </CompressionGroup>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailableToCompress)
