import React from 'react'
import { Provider } from 'react-redux'
import store from '../../store'
import BatchCompressionsIndex from '../BatchCompressions'

const BatchCompressionsApp = props => (
  <Provider store={store}>
    <BatchCompressionsIndex {...props} />
  </Provider>
)

export default BatchCompressionsApp
