import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import Compression from '../compression'

export default class CompressionCard extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    selectable: PropTypes.bool,
    checked: PropTypes.bool,
    showMenu: PropTypes.bool,
    original: PropTypes.object,
    compressed: PropTypes.object,
    compression: PropTypes.object
  }

  get imageSrc() {
    const { compressed, original } = this.props
    return compressed && compressed.src || original.src
  }

  renderImage = () => {
    const { compressed, original } = this.props

    return (
      <div className='w-100 btn btn-light' style={{maxWidth: '94px', padding: '0px', margin: '0px'}}>
        <img
          src={this.imageSrc}
          className='mx-auto'
          style={{maxWidth: '94px', maxHeight: '64px'}}
        />
      </div>
    )
  }

  renderLinkToImage = () => {
    const { compressed, original } = this.props

    return (
      <a href={this.imageSrc} target='_blank'>
        {this.renderImage()}
      </a>
    )
  }

  renderCompressionLink = () => {
    const { compressed, original, compression } = this.props

    return (
      <Compression
        image={original}
        compressedImage={compressed}
        compression={compression}
        closeOnAction={true}
        key={original.id}
      >
        {this.renderImage()}
      </Compression>
    )
  }

  renderCheckboxCol = () => {
    const { onChange, checked } = this.props

    return (
      <td width='22'>
        <center>
          <input
            type='checkbox'
            checked={checked}
            onChange={onChange}
          />
        </center>
      </td>
    )
  }

  render = () => {
    const { children, original, selectable, showMenu } = this.props

    return (
      <Fragment>
        <div className='col-sm-6' style={{padding: '2px'}}>
          <div className='card h-100 justify-content-center compressable-item'>
            <div>
              <table className='w-100'>
                <tbody>
                  <tr>
                    {selectable && this.renderCheckboxCol()}
                    <td width='100' height='75'>
                      {showMenu && this.renderCompressionLink() || this.renderLinkToImage()}
                    </td>
                    <td className='compressable-item-info'>
                      <div className='w-100 h-100' style={{lineHeight: 'normal'}}>
                        <small>
                          {original.featured && <div className='text-info'>Featured image</div>}
                          {children}
                        </small>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
