import React, { Component, Fragment } from 'react'
import Compression from './compression'
import PropTypes from 'prop-types'
import { updateImage } from '../api/image'
import { humanSizeInKb, humanSizeInPixels } from '../utils/size.utils'
import LoaderCircle from './loader_circle'
import ErrorsList from './errors_list'
import InputFormGroup from './inputs/input_form_group'

export default class EditImage extends Component {
  static propTypes = {
    image: PropTypes.object.isRequired,
    message: PropTypes.string,
    resource_path: PropTypes.string.isRequired
  }

  constructor(props, context) {
    super(props, context)
    const {
      image, image: { original_src, alt, title, featured, src, width, height, size, type, errors }
    } = props
    this.state = {
      alt, title, original_src, src, featured, width, height, size, type, image,
      showSaveButton: false, showAlerts: true, waiting: false, errors
    }
  }

  canShowSaveButton = ({ alt, title, original_src }) => {
    const givenImage = this.state.image
    return givenImage.alt != alt
           || givenImage.title != title
           || givenImage.original_src != original_src
  }

  handleSrcChange = (event) => {
    const original_src = event.target.value
    const { alt, title } = this.state
    this.setState({
      original_src,
      showAlerts: false,
      showSaveButton: this.canShowSaveButton({ alt, title, original_src })
    })
  }

  handleAltChange = (event) => {
    const alt = event.target.value
    const { title, original_src } = this.state
    this.setState({
      alt,
      showAlerts: false,
      showSaveButton: this.canShowSaveButton({ alt, title, original_src })
    })
  }

  handleTitleChange = (event) => {
    const title = event.target.value
    const { alt, original_src } = this.state
    this.setState({
      title,
      showAlerts: false,
      showSaveButton: this.canShowSaveButton({ alt, title, original_src })
    })
  }

  updateStates = (result) => {
    const {
      image, message, errors, type,
      image: { alt, title, original_src, src, width, height, size }
    } = result
    this.setState({
      image: { type, ...image },
      alt, title, original_src, src, width, height, size, type,
      waiting: false,
      showAlerts: true,
      message,
      errors: errors || [],
      showSaveButton: false
    })
  }

  handleSave = async () => {
    this.setState({ waiting: true })
    const { alt, title, original_src } = this.state
    const params = { image: { alt, title, original_src } }
    const result = await updateImage(this.props.image, params)
    this.updateStates(result)
  }

  get errors() {
    const { errors, showAlerts } = this.state
    return(
      <ErrorsList
        message={'The following errors are prohibited this image from being saved:'}
        errors={errors}
        visible={errors.length !== 0 && showAlerts}
      />
    )
  }

  get message() {
    const { message, showAlerts } = this.state
    return(
      message && showAlerts &&
      <div className='alert alert-success'>
        {message}
      </div>
    )
  }

  get srcFormGroup() {
    return(
      <InputFormGroup
        label='Original src'
        value={this.state.original_src || ''}
        onChange={this.handleSrcChange}
        placeholder={I18n.t('image.placeholder.src')}
      />
    )
  }

  get altFormGroup() {
    return(
      <InputFormGroup
        label='Alt'
        value={this.state.alt || ''}
        onChange={this.handleAltChange}
        placeholder={I18n.t('image.placeholder.alt')}
      />
    )
  }

  get titleFormGroup() {
    const { title, featured } = this.state
    return(
      !featured &&
      <InputFormGroup
        label='Title'
        value={title || ''}
        onChange={this.handleTitleChange}
        placeholder={I18n.t('image.placeholder.title')}
      />
    )
  }

  get featuredBadge() {
    return(
      this.state.featured &&
      <span className='badge badge-primary w-100' style={{marginBottom: '10px'}}>
        Featured image
      </span>
    )
  }

  get picture() {
    const { src } = this.state
    return(
      <a href={src} target='_blank' className='btn btn-sm btn-light w-100'>
        <img src={src} className='img-thumbnail bg-light' />
      </a>
    )
  }

  get compressionMenu() {
    const { image, width, height, size, type } = this.state
    const enableCompress = type && !type.includes('svg')
    return(
      width && height && size && enableCompress &&
      <Compression
        image={image}
        onApply={this.updateStates}
      />
    )
  }

  get sizeInPixels() {
    const { width, height } = this.state
    return(
      width && height &&
      <span className='btn btn-light disabled'>
        {humanSizeInPixels(width, height)}
      </span>
    )
  }

  get sizeInKB() {
    const { size } = this.state
    return(
      size &&
      <span className='btn btn-light disabled'>
        {humanSizeInKb(size)}
      </span>
    )
  }

  get saveButton() {
    return(
      (this.state.errors.length !== 0 || this.state.showSaveButton) &&
      <button
        type='button'
        className='btn btn-warning'
        onClick={this.handleSave}
      >
        {I18n.t('actions.save')}
      </button>
    )
  }

  render() {
    if (this.state.waiting) return <LoaderCircle />
    return(
      <Fragment>
        <div className='row'>
          <div className='col-sm-10'>
            {this.errors}
            {this.message}
            {this.srcFormGroup}
            {this.altFormGroup}
            {this.titleFormGroup}
          </div>
          <div className='col-sm-2'>
            {this.featuredBadge}
            <div className='w-100 btn-group-vertical'>
              {this.picture}
              {this.compressionMenu}
              {this.sizeInPixels}
              {this.sizeInKB}
              {this.saveButton}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
