import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OtherCompressions from './OtherCompressions'

const mapStateToProps = state => (
  { queuedCompressions: state.compression.queuedCompressions }
)

class QueuedCompressions extends Component {
  render = () => (
    <OtherCompressions
      compressions={this.props.queuedCompressions}
      caption='Queued'
      className='compression-group-queued'
    />
  )
}

export default connect(mapStateToProps, null)(QueuedCompressions)
