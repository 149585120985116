import React from 'react'
import { toPositiveInt } from '../../utils/events'

const SizeInput = (props) => {
  return(
    <input
      type='number'
      value={props.value}
      onChange={e => props.onChange(toPositiveInt(e.target.value, props.max))}
      min={1}
      max={props.max}
      step={1}
      className='form-control'
      placeholder={props.placeholder}
    />
  )
}

export default SizeInput
