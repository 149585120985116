import React from 'react'

const InputFormGroup = (props) => {
  const { errors, showAlerts, recommendedLength, maxLength } = props

  const elementName = props.type == 'textarea' ? 'textarea' : 'input'
  let classNames = ['form-control', 'text-muted']
  if (showAlerts && errors && errors.length !== 0) classNames.push('is-invalid')
  const params = {
    maxLength: maxLength,
    className: classNames.join(' '),
    value: props.value || '',
    onChange: props.onChange,
    autoComplete: 'off',
    placeholder: props.placeholder
  }

  const recommendation = (
    <small className='form-text text-muted float-right'>
      <span className={maxLength && params.value.length > maxLength ? 'text-danger' : null}>
        {params.value.length} characters&nbsp;
      </span>
      ({[
        recommendedLength ? `Recommended max length: ${recommendedLength}` : null,
        maxLength ? `allowed: ${maxLength}` : null
      ].filter(item => item).join(', ')})
    </small>
  )
  const errorsList = (
    <span className='invalid-feedback'>
      {errors && errors.join(', ')}
    </span>
  )

  return(
    <div className='form-group'>
      <label>{props.label}</label>
      <small className="form-text text-muted text-break">{props.tooltip}</small>
      {React.createElement(elementName, params)}
      {(maxLength || recommendedLength) && recommendation}
      {showAlerts && errorsList}
    </div>
  )
}

export default InputFormGroup
