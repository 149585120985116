import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxAutoloader } from 'redux-autoloader';

import ApplyingCompressions from './ApplyingCompressions'
import SuccessCompressions from './SuccessCompressions'
import WorkingCompressions from './WorkingCompressions'
import QueuedCompressions from './QueuedCompressions'
import FailedCompressions from './FailedCompressions'
import AvailableToCompress from './AvailableToCompress'
import FailedToApplyCompressions from './FailedToApplyCompressions'

import { updateImages, refreshCompressionsRequest } from '../../actions/compression'

const mapStateToProps = state => (
  { allImages: state.compression.allImages }
)

const mapDispatchToProps = { updateImages, refreshCompressionsRequest }

class BatchCompressions extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired
  }

  constructor(props, context) {
    super(props, context)
    const { images, updateImages } = props
    updateImages(images)
  }

  renderFallbackMessage = () => (
    <div className='alert alert-warning'>
      {I18n.t('image.compressions.empty_fallback_message')}
    </div>
  )

  render = () => {
    if (this.props.allImages.length === 0) return this.renderFallbackMessage()

    return (
      <Fragment>
        <ApplyingCompressions />
        <SuccessCompressions />
        <WorkingCompressions />
        <QueuedCompressions />
        <FailedCompressions />
        <AvailableToCompress />
        <FailedToApplyCompressions />
      </Fragment>
    )
  }
}

const LOADER_PARAMS = {
  name: 'batch_compressions_loader',
  loadOnInitialize: false,
  apiCall: props => props.refreshCompressionsRequest(),
  autoRefreshInterval: 1500,
}

const ConnectedComponent = reduxAutoloader(LOADER_PARAMS)(BatchCompressions)
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent)
