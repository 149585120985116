import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap4-modal'
import PropTypes from 'prop-types'

import Dropzone from 'react-dropzone'
import { SocialIcon } from 'react-social-icons'
import Linkify from 'react-linkify'
import InputFormGroup from './inputs/input_form_group'
import SwitchBox from './inputs/switch_box'
import Confirmation from './confirmation'

import Moment from 'moment'
import Datetime from 'react-datetime'

import { createSocialMessage } from './../api/social_message'
import { uploadFile } from './../api/files'

const MIN_PUBLISH_INTERVAL_MINUTES = 360

export default class SocialShare extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired
  }

  static MESSAGE_STATES_TO_CLASS_NAMES = {
    'SUCCESS': 'text-success',
    'ERROR_FATAL': 'text-danger',
    'DRAFT': 'text-secondary',
    'WAITING': 'text-warning'
  }

  static MESSAGE_STATES_TO_HUMAN_NAMES = {
    'SUCCESS': 'SUCCESS',
    'ERROR_FATAL': 'ERROR',
    'DRAFT': 'DRAFT',
    'WAITING': 'WAITING'
  }

  constructor(props, context) {
    super(props, context)
    const { post } = props
    const default_channel = post.social_media_channels[0]
    this.state = {
      visible: false,
      messageBody: `${post.title}\n${post.url}`,
      channelIds: default_channel && [default_channel.id] || [],
      imageUrl: post.featured_image,
      usePicture: true,
      selectedMoment: new Moment(),
      availableImages: [post.featured_image, ...post.images].filter(i => i),
      broadcast_messages: post.broadcast_messages
    }
  }

  handleShow = () => { this.setState({ visible: true } ) }

  handleHide = () => { this.setState({ visible: false }) }

  get closeButton() {
    return(
      <button type='button' className='btn btn-light' onClick={this.handleHide}>
        {I18n.t('actions.close')}
      </button>
    )
  }

  renderModalHeader = () => {
    const { post } = this.props

    return(
      <Fragment>
        <h5 className='modal-title'>{post.title}</h5>
        <button type='button' className='close' onClick={this.handleHide}>
          <span>×</span>
        </button>
      </Fragment>
    )
  }

  renderModalFooter = () => {
    return(
      <Fragment>
        {this.closeButton}
      </Fragment>
    )
  }

  toggleChannel = (channelId) => {
    const { channelIds } = this.state
    let ids
    if (channelIds.includes(channelId)) {
      if (channelIds.length <= 1) return
      ids = channelIds.filter(i => i !== channelId)
    } else {
      ids = [channelId, ...channelIds]
    }
    this.setState({ channelIds: ids })
  }

  renderChannel = (channel, key) => {
    const { channelIds } = this.state
    const className = channelIds.includes(channel.id) ? 'disabled btn-outline-dark' : 'btn-light'
    return(
      <button
        key={key}
        type='button'
        className={`btn btn-sm ${className}`}
        onClick={() => this.toggleChannel(channel.id)}
      >
        <SocialIcon network={channel.social_network} style={{width: '18px', height: '18px', marginTop: '-2px', marginRight: '2px'}} />
        {channel.name}
      </button>
    )
  }

  renderChannels = () => {
    const { post } = this.props

    return (
      <Fragment>
        <b>{I18n.t('actions.select_channels')}:&nbsp;</b>
        <span className='social-media-channels'>
          {post.social_media_channels.map(this.renderChannel)}
        </span>
      </Fragment>
    )
  }

  messageStatusBadge = (statusName) => {
    const className = SocialShare.MESSAGE_STATES_TO_CLASS_NAMES[statusName] || 'text-warning'
    const status = SocialShare.MESSAGE_STATES_TO_HUMAN_NAMES[statusName] || 'IN PROCESS'

    return <span className={`badge ${className}`}>{status}</span>
  }

  renderBroadcastMessage = (item, key) => {
    return(
      <tr key={key}>
        <td className='' style={{marginRight: '0px'}}>
          <center>
            <SocialIcon network={item.social_network} />
            <br />
            {this.messageStatusBadge(item.status)}
          </center>
        </td>
        <td className=''>
          <small className='form-text text-muted'>
            <b>{item.channel_name}</b>,&nbsp;
            {new Moment(item.trigger_at).format('MMM DD YYYY, h:mm A')}
          </small>
          {this.renderPreview(item.body, item.photo_url)}
          <hr />
        </td>
      </tr>
    )
  }

  renderBroadcastMessages = () => {
    const { broadcast_messages } = this.state

    return (
      <Fragment>
        <hr />
        <b>{I18n.t('captions.previous_posts')}:&nbsp;</b>
        <table className='w-100 previous-social-media-posts'>
          <tbody>
            {broadcast_messages.map(this.renderBroadcastMessage)}
          </tbody>
        </table>
      </Fragment>
    )
  }

  schedule = async (publishImmediately, autoSchedule = false) => {
    const {
      messageBody, imageUrl, channelIds, usePicture, selectedMoment
    } = this.state
    channelIds.forEach(async (channelId) => {
      const params = {
        message: {
          body: messageBody,
          photo_url: usePicture ? imageUrl : null,
          social_post_id: this.props.post.id,
          trigger_at: publishImmediately ? null : selectedMoment.toISOString(),
          auto_schedule: autoSchedule
        },
        channel_id: channelId
      }
      const response = await createSocialMessage(params)
      const { broadcast_messages } = this.state
      this.setState({ broadcast_messages: [response, ...broadcast_messages] })
    })
  }

  handleSchedule = () => { this.schedule(false) }
  handleAutoSchedule = () => { this.schedule(true, true) }
  postNow = () => { this.schedule(true) }

  handleMessageBodyChange = (event) => {
    const messageBody = event.target.value
    this.setState({ messageBody })
  }

  handleFileAttached = async (files) => { // TODO: put it into separate component
    const file = files[0]
    const result = await uploadFile(file)

    this.setState({
      imageUrl: result.url,
      availableImages: [result.url, ...this.state.availableImages]
    })
  }

  renderAttachedPicture = () => {
    const { imageUrl } = this.state

    return imageUrl &&
      <a href={imageUrl} target='_blank'>
        <img src={imageUrl} className='w-100 rounded' style={{marginTop: '10px'}} />
      </a>
  }

  renderImageSwitch = () => {
    const { availableImages, imageUrl } = this.state

    const totalImages = availableImages.length
    if (totalImages < 2) return null

    const currentIndex = availableImages.indexOf(imageUrl)
    const nextIndex = currentIndex === totalImages - 1 ? 0 : currentIndex + 1
    const previousIndex = currentIndex === 0 ? totalImages - 1 : currentIndex - 1

    const previousImage = availableImages[previousIndex]
    const nextImage = availableImages[nextIndex]

    const setPrevious = () => this.setState({ imageUrl: previousImage })
    const setNext = () => this.setState({ imageUrl: nextImage })

    return (
      <div className='btn-group' style={{margin: '0px 5px'}}>
        <button type='button' className='btn btn-sm btn-outline-dark' onClick={setPrevious}>{'<'}</button>
        <button type='button' className='btn btn-sm btn-outline-dark'>Image {currentIndex + 1} / {totalImages}</button>
        <button type='button' className='btn btn-sm btn-outline-dark' onClick={setNext}>{'>'}</button>
      </div>
    )
  }

  renderPreview = (body, pictureUrl) => {
    return (
      <div className='w-100 border rounded p-3' style={{margin: '5px 15px', minHeight: '80px', textOverflow: 'ellipsis', maxWidth: '500px', overflow: 'hidden'}}>
        <Linkify>
          <span style={{whiteSpace: 'pre-line'}}>{body}</span>
          {pictureUrl &&
           <a style={{display: 'block'}} href={pictureUrl} target='_blank'>
             <img src={pictureUrl} className='rounded' style={{marginTop: '10px', maxWidth: '100%'}} />
           </a>}
        </Linkify>
      </div>
    )
  }

  renderPictureSwitch = () => {
    const { usePicture } = this.state

    return (
      <SwitchBox
        checked={usePicture}
        onChange={() => this.setState({ usePicture: !usePicture })}
        id={`switch-picture-${this.props.post.id}`}
        label='Use image'
      />
    )
  }

  renderDropZone = () => {
    return (
      <Dropzone
          onDrop={this.handleFileAttached}
          onFileDialogCancel={this.onCancel}
          className='upload-drop-zone mt-4'
          multiple={false}
      >
        <span>Click or drag to upload an image</span>
      </Dropzone>
    )
  }

  renderModalBody = () => {
    const { usePicture, broadcast_messages } = this.state

    if (this.props.post.social_media_channels.length === 0) {
      return (
        <div className='alert alert-warning'>
          Please connect at least one of your social network accounts to HubSpot (
          <a target='_blank'
            href='https://knowledge.hubspot.com/articles/kcs_article/social/connect-your-social-media-accounts-to-hubspot'
          >Read more</a>).
        </div>
      )
    }

    return (
      <Fragment>

        {this.renderChannels()}
        <hr />
        <div>
          {this.renderPictureSwitch()}
          <div className='float-right' style={{display: 'inline-block', marginTop: '-5px'}}>
            {usePicture && this.renderImageSwitch()}
            <Datetime
              inputProps={{readOnly: true, className: 'btn btn-sm btn-outline-dark'}}
              value={this.state.selectedMoment}
              onChange={selectedMoment => this.setState({ selectedMoment })}
            />&nbsp;
            <span style={{marginRight: '4px'}}>
              <Confirmation
                btnText={I18n.t('actions.schedule')}
                onConfirm={this.handleSchedule}
                className='btn btn-sm btn-outline-dark'
              >
                Schedule post
              </Confirmation>
            </span>
            <span style={{marginRight: '4px'}}>
              <Confirmation
                btnText={I18n.t('actions.auto_schedule')}
                onConfirm={this.handleAutoSchedule}
                className='btn btn-sm btn-outline-dark'
              >
                {I18n.t('broadcast_messages.auto_schedule_description', {
                  duration: Moment.duration(MIN_PUBLISH_INTERVAL_MINUTES, 'minutes').humanize()
                })}
              </Confirmation>
            </span>
            <Confirmation
              btnText={I18n.t('actions.post_now')}
              onConfirm={this.postNow}
              className='btn btn-sm btn-outline-dark'
            >
              {I18n.t('actions.post_now')}
            </Confirmation>
          </div>
        </div>
        <Fragment>
          <InputFormGroup
            type='textarea'
            label='Message body'
            value={this.state.messageBody || ''}
            onChange={this.handleMessageBodyChange}
            placeholder={I18n.t('image.placeholder.message_body')}
            recommendedLength={280}
          />
        </Fragment>

        {usePicture && this.renderDropZone() || <br/>}
        <hr />

        <b>It will look like:</b>
        <div className='social-post-preview-area'>
          {this.renderPreview(this.state.messageBody, usePicture && this.state.imageUrl)}
        </div>

        {broadcast_messages.length > 0 && this.renderBroadcastMessages()}
      </Fragment>
    )
  }

  render() {
    return (
      <Fragment>
        <button type='button' className='btn btn-sm btn-outline-dark' onClick={this.handleShow}>
          {I18n.t('actions.share')}
        </button>
        <Modal dialogClassName='modal-lg' visible={this.state.visible}>
          <div className='modal-header'>{this.renderModalHeader()}</div>
          <div className='modal-body'>{this.renderModalBody()}</div>
          <div className='modal-footer'>{this.renderModalFooter()}</div>
        </Modal>
      </Fragment>
    )
  }
}
