import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { updateMetatext } from '../api/metatext'
import LoaderCircle from './loader_circle'
import ErrorsList from './errors_list'
import InputFormGroup from './inputs/input_form_group'

export default class EditMetatext extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    message: PropTypes.string,
    resource_path: PropTypes.string.isRequired
  }

  constructor(props, context) {
    super(props, context)
    const {
      post, errors,
      post: { title, meta_description }
    } = props
    this.state = {
      title, meta_description, post,
      showSaveButton: false, showAlerts: true, waiting: false, errors: errors
    }
  }

  canShowSaveButton = ({ title, meta_description }) => {
    const givenPost = this.state.post
    return givenPost.title != title
           || givenPost.meta_description != meta_description
  }

  handleMetaDescriptionChange = (event) => {
    const meta_description = event.target.value
    const { title } = this.state
    this.setState({
      meta_description,
      showAlerts: false,
      showSaveButton: this.canShowSaveButton({ title, meta_description })
    })
  }

  handleTitleChange = (event) => {
    const title = event.target.value
    const { meta_description } = this.state
    this.setState({
      title,
      showAlerts: false,
      showSaveButton: this.canShowSaveButton({ title, meta_description })
    })
  }

  updateStates = (result) => {
    const { post, message, errors } = result
    const { title, meta_description } = (post || {})
    this.setState({
      post,
      title, meta_description,
      waiting: false,
      showAlerts: true,
      message,
      errors: errors || {},
      showSaveButton: false
    })
  }

  handleSave = async () => {
    this.setState({ waiting: true })
    const { title, meta_description } = this.state
    const params = { title, meta_description }
    const { post, resource_path } = this.props
    const result = await updateMetatext(post, resource_path, params)
    this.updateStates(result)
  }

  get errors() {
    const { errors, showAlerts } = this.state
    const baseErrors = errors && errors['base'] || []
    return(
      <ErrorsList
        message={`The following errors are prohibited this ${this.props.post.type} from being saved:`}
        errors={Object.values(baseErrors)}
        visible={Object.values(baseErrors).length !== 0 && showAlerts}
      />
    )
  }

  get message() {
    const { message, showAlerts } = this.state
    return(
      message && showAlerts &&
      <div className='alert alert-success'>
        {message}
      </div>
    )
  }

  get inputs() {
    return this.state.post && (
      <Fragment>
        {this.titleFormGroup}
        {this.metaDescriptionFormGroup}
      </Fragment>
    )
  }

  get metaDescriptionFormGroup() {
    return(
      <InputFormGroup
        type='textarea'
        errors={this.state.errors['meta_description']}
        showAlerts={this.state.showAlerts}
        label='Meta description'
        recommendedLength={150}
        maxLength={200}
        value={this.state.meta_description || ''}
        onChange={this.handleMetaDescriptionChange}
        placeholder={I18n.t('metatext.placeholder.meta_description')}
      />
    )
  }

  get titleFormGroup() {
    return(
      <InputFormGroup
        errors={this.state.errors['title']}
        showAlerts={this.state.showAlerts}
        label='Title'
        recommendedLength={60}
        maxLength={100}
        value={this.state.title || ''}
        onChange={this.handleTitleChange}
        placeholder={I18n.t('metatext.placeholder.title')}
      />
    )
  }

  get showButton() {
    const { post } = this.props
    return(
      <a href={post.absolute_url} target='_blank' className='btn btn-light w-100'>
        {I18n.t('actions.show')}&nbsp;
        {post.is_draft && <span className='badge badge-secondary'>{I18n.t('draft')}</span>}
      </a>
    )
  }

  get saveButton() {
    const { post, errors } = this.state

    return(
      (post && errors && Object.values(errors).length !== 0 || this.state.showSaveButton) &&
      <button
        type='button'
        className='btn btn-warning'
        onClick={this.handleSave}
      >
        {I18n.t('actions.save')}
      </button>
    )
  }

  render() {
    if (this.state.waiting) return <LoaderCircle />
    return(
      <Fragment>
        <div className='row'>
          <div className='col-sm-10'>
            {this.errors}
            {this.message}
            {this.inputs}
          </div>
          <div className='col-sm-2'>
            <div className='w-100 btn-group-vertical'>
              {this.showButton}
              {this.saveButton}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
