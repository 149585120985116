import React from 'react'
import { Provider } from 'react-redux'
import store from '../../store'
import PaymentsIndex from '../Payments'

const PaymentsApp = props => {
  const { public_key, ...params } = props
  const data = {...params, stripe: Stripe(public_key)}

  return (
    <Provider store={store}>
      <PaymentsIndex {...data} />
    </Provider>
  )
}

export default PaymentsApp
