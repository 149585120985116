import { toggleInArray } from '../utils/arrays'
import { groupImages, availableIds } from '../utils/compressions'
import {
  UPDATE_IMAGES,
  SET_BATCH_COMPRESSION_PARAMS,
  SET_IDS_TO_COMPRESS,
  SET_SELECTED_SUCCESS_IDS,
  SET_SELECTED_FAILED_IDS,
  EMPTY_IDS_TO_COMPRESS,
  EMPTY_SELECTED_SUCCESS_IDS,
  EMPTY_SELECTED_FAILED_IDS,
  TOGGLE_ID_TO_COMPRESS,
  TOGGLE_SELECTED_SUCCESS_ID,
  TOGGLE_SELECTED_FAILED_ID,
  EMPTY_AVAILABLE_IMAGES,
  EMPTY_SUCCESS_COMPRESSIONS,
  EMPTY_FAILED_COMPRESSIONS,
  SELECT_ALL_AVAILABLE_IMAGES,
  SELECT_ALL_SUCCESS_COMPRESSIONS,
  SELECT_ALL_FAILED_COMPRESSIONS
} from '../actions/compression'

const initialState = {
  batchParams: {
    quality: 100,
    maxWidth: 2000,
    maxHeight: 2000,
    format: null,
  },
  idsToCompress: [],
  selectedSuccessIds: [],
  selectedFailedIds: [],
  allImages: [],
  availableImages: [],
  successCompressions: [],
  failedCompressions: [],
  failedToApply: [],
  workingCompressions: [],
  applyingCompressions: [],
  queuedCompressions: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_BATCH_COMPRESSION_PARAMS:
      return { ...state, batchParams: { ...state.batchParams, ...action.payload } }
    case SET_IDS_TO_COMPRESS:
      return { ...state, idsToCompress: action.idsToCompress }
    case EMPTY_IDS_TO_COMPRESS:
      return { ...state, idsToCompress: [] }
    case EMPTY_AVAILABLE_IMAGES:
      return {
        ...state,
        idsToCompress: [],
        availableImages: state.availableImages.filter(i => !state.idsToCompress.includes(i.id))
      }
    case SET_SELECTED_SUCCESS_IDS:
      return { ...state, selectedSuccessIds: action.selectedSuccessIds }
    case EMPTY_SELECTED_SUCCESS_IDS:
      return { ...state, selectedSuccessIds: [] }
    case EMPTY_SUCCESS_COMPRESSIONS:
      return {
        ...state,
        selectedSuccessIds: [],
        successCompressions: state.successCompressions.filter(i => !state.selectedSuccessIds.includes(i.id))
      }
    case SET_SELECTED_FAILED_IDS:
      return { ...state, selectedFailedIds: action.selectedFailedIds }
    case EMPTY_FAILED_COMPRESSIONS:
      return {
        ...state,
        selectedFailedIds: [],
        failedCompressions: state.failedCompressions.filter(i => !state.selectedFailedIds.includes(i.id))
      }
    case EMPTY_SELECTED_FAILED_IDS:
      return { ...state, selectedFailedIds: [] }
    case TOGGLE_ID_TO_COMPRESS:
      return {
        ...state,
        idsToCompress: toggleInArray(state.idsToCompress, action.id)
      }
    case TOGGLE_SELECTED_SUCCESS_ID:
      return {
        ...state,
        selectedSuccessIds: toggleInArray(state.selectedSuccessIds, action.id)
      }
    case TOGGLE_SELECTED_FAILED_ID:
      return {
        ...state,
        selectedFailedIds: toggleInArray(state.selectedFailedIds, action.id)
      }
    case UPDATE_IMAGES: {
      const {
        available, complete, applying, failed, failedToApply, working, queued
      } = groupImages(action.images)
      return {
        ...state,
        failedToApply,
        allImages: action.images,
        availableImages: available,
        successCompressions: complete,
        failedCompressions: failed,
        workingCompressions: working,
        applyingCompressions: applying,
        queuedCompressions: queued,
        idsToCompress: availableIds(state.idsToCompress, available),
        selectedSuccessIds: availableIds(state.selectedSuccessIds, complete),
        selectedFailedIds: availableIds(state.selectedFailedIds, failed)
      }
    }
    case SELECT_ALL_AVAILABLE_IMAGES:
      return { ...state, idsToCompress: state.availableImages.map(i => i.id) }
    case SELECT_ALL_SUCCESS_COMPRESSIONS:
      return { ...state, selectedSuccessIds: state.successCompressions.map(i => i.id) }
    case SELECT_ALL_FAILED_COMPRESSIONS:
      return { ...state, selectedFailedIds: state.failedCompressions.map(i => i.id) }
    default:
      return state
  }
}
