import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { humanSizeInKb, humanSizeInPixels } from '../../utils/size.utils'

import CompressionGroup from './CompressionGroup'
import CompressionCard from './CompressionCard'
import PostInfo from './PostInfo'

import {
  applySuccessCompressionsRequest,
  rejectSuccessCompressionsRequest,
  selectAllSuccessCompressions,
  emptySelectedSuccessIds,
  toggleSelectedSuccessId,
} from '../../actions/compression'

const mapStateToProps = state => {
  const { selectedSuccessIds, successCompressions } = state.compression
  return { selectedSuccessIds, successCompressions }
}

const mapDispatchToProps = {
  applySuccessCompressionsRequest,
  rejectSuccessCompressionsRequest,
  selectAllSuccessCompressions,
  emptySelectedSuccessIds,
  toggleSelectedSuccessId,
}

class SuccessCompressions extends Component {
  renderItem = (compression) => {
    const original = compression.image
    const compressed = compression.compressed_image
    const { selectedSuccessIds, toggleSelectedSuccessId } = this.props

    return (
      <CompressionCard
        key={original.id}
        original={original}
        compressed={compressed}
        compression={compression}
        showMenu={true}
        onChange={() => toggleSelectedSuccessId(compression.id)}
        checked={selectedSuccessIds.includes(compression.id)}
        selectable={true}
      >
        <PostInfo url={original.post_url} title={original.post_title}/>
        {humanSizeInPixels(original.width, original.height)} -> {humanSizeInPixels(compressed.width, compressed.height)}
        <br/>
        {humanSizeInKb(original.size)} -> {humanSizeInKb(compressed.size)}
      </CompressionCard>
    )
  }

  renderButtons = () => {
    const {
      selectedSuccessIds, emptySelectedSuccessIds, selectAllSuccessCompressions,
      applySuccessCompressionsRequest, rejectSuccessCompressionsRequest
    } = this.props

    return (
      <Fragment>
        <button type='button' className='btn btn-sm btn-secondary' onClick={selectAllSuccessCompressions}>Select All</button>&nbsp;
        <button type='button' className='btn btn-sm btn-secondary' onClick={emptySelectedSuccessIds}>Unselect All</button>&nbsp;
        {selectedSuccessIds.length > 0 && <Fragment>
          <button type='button' className='btn btn-sm btn-success' onClick={applySuccessCompressionsRequest}>Apply selected</button>&nbsp;
          <button type='button' className='btn btn-sm btn-danger' onClick={rejectSuccessCompressionsRequest}>Reject selected</button>&nbsp;
        </Fragment>}
      </Fragment>
    )
  }

  render = () => {
    const { successCompressions } = this.props
    if (successCompressions.length === 0) return null

    return (
      <CompressionGroup
        caption='Compressed'
        className='compression-group-compressed'
        buttons={this.renderButtons()}
      >
        {successCompressions.map(this.renderItem)}
      </CompressionGroup>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessCompressions)
