import sleep from './sleep'

export async function askWhile(request, condition) {
  let data = await request()
  do {
    await sleep(500)
    data = await request()
  } while (condition(data))
  return data
}
