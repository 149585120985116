import filesize from 'filesize'

function humanSizeInKb(size) {
  return size && filesize(size)
}

function humanSizeInPixels(width, height) {
  return width && height && `${width} × ${height}`
}

export { humanSizeInKb, humanSizeInPixels }
