import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

export default class CompressionGroup extends Component {
  static propTypes = {
    caption: PropTypes.string.isRequired,
    buttons: PropTypes.object,
    className: PropTypes.string
  }

  render = () => {
    const { caption, buttons, children, className, selectable } = this.props

    return (
      <Fragment>
        <div className={`card ${className}`.trim()}>
          <div className='card-header'>
            {caption}
            <span className='float-right'>
              {buttons}
            </span>
          </div>
          <div className='card-body' style={{padding: '3px 17px'}}>
            <div className='row'>
              {children}
            </div>
          </div>
        </div>
        <br />
      </Fragment>
    )
  }
}
