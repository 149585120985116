import React from 'react'
import { humanSizeInKb, humanSizeInPixels } from '../utils/size.utils'

const ImageCard = (props) => {
  if (!props.image) return null
  const { image: { size, width, height, src }, title } = props
  if (!size) return null
  return(
    <a href={src} className='card btn btn-light card' target='_blank'>
      <div className='card-body'>
        <h5 className='card-title'>{title}</h5>
        <img src={src} className='w-100' />
        <br />
        <br />
        <p className='card-text'>{humanSizeInPixels(width, height)}</p>
        <p className='card-text'>{humanSizeInKb(size)}</p>
      </div>
    </a>
  )
}

export default ImageCard
