import React from 'react'

const SwitchBox = (props) => {
  return (
    <span className='switch switch-sm'>
      <input
        checked={props.checked}
        type='checkbox'
        className='switch switch-sm'
        onChange={props.onChange}
        id={props.id}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </span>
  )
}

export default SwitchBox
