import { createAction } from './utils'

export const CREATE_COMPRESSIONS_REQUEST = 'CREATE_COMPRESSIONS_REQUEST'
export const APPLY_SUCCESS_COMPRESSIONS_REQUEST = 'APPLY_SUCCESS_COMPRESSIONS_REQUEST'
export const REJECT_SUCCESS_COMPRESSIONS_REQUEST = 'REJECT_SUCCESS_COMPRESSIONS_REQUEST'
export const RETRY_FAILED_COMPRESSIONS_REQUEST = 'RETRY_FAILED_COMPRESSIONS_REQUEST'
export const REJECT_FAILED_COMPRESSIONS_REQUEST = 'REJECT_FAILED_COMPRESSIONS_REQUEST'
export const REFRESH_COMPRESSIONS_REQUEST = 'REFRESH_COMPRESSIONS_REQUEST'
export const UPDATE_IMAGES = 'UPDATE_IMAGES'
export const SET_BATCH_COMPRESSION_PARAMS = 'SET_BATCH_COMPRESSION_PARAMS'
export const SET_IDS_TO_COMPRESS = 'SET_IDS_TO_COMPRESS'
export const SET_SELECTED_SUCCESS_IDS = 'SET_SELECTED_SUCCESS_IDS'
export const SET_SELECTED_FAILED_IDS = 'SET_SELECTED_FAILED_IDS'
export const EMPTY_IDS_TO_COMPRESS = 'EMPTY_IDS_TO_COMPRESS'
export const EMPTY_SELECTED_SUCCESS_IDS = 'EMPTY_SELECTED_SUCCESS_IDS'
export const EMPTY_SELECTED_FAILED_IDS = 'EMPTY_SELECTED_FAILED_IDS'
export const TOGGLE_ID_TO_COMPRESS = 'TOGGLE_ID_TO_COMPRESS'
export const TOGGLE_SELECTED_SUCCESS_ID = 'TOGGLE_SELECTED_SUCCESS_ID'
export const TOGGLE_SELECTED_FAILED_ID = 'TOGGLE_SELECTED_FAILED_ID'
export const EMPTY_AVAILABLE_IMAGES = 'EMPTY_AVAILABLE_IMAGES'
export const EMPTY_SUCCESS_COMPRESSIONS = 'EMPTY_SUCCESS_COMPRESSIONS'
export const EMPTY_FAILED_COMPRESSIONS = 'EMPTY_FAILED_COMPRESSIONS'
export const SELECT_ALL_AVAILABLE_IMAGES = 'SELECT_ALL_AVAILABLE_IMAGES'
export const SELECT_ALL_SUCCESS_COMPRESSIONS = 'SELECT_ALL_SUCCESS_COMPRESSIONS'
export const SELECT_ALL_FAILED_COMPRESSIONS = 'SELECT_ALL_FAILED_COMPRESSIONS'

export const createCompressionsRequest = createAction(CREATE_COMPRESSIONS_REQUEST)
export const applySuccessCompressionsRequest = createAction(APPLY_SUCCESS_COMPRESSIONS_REQUEST)
export const rejectSuccessCompressionsRequest = createAction(REJECT_SUCCESS_COMPRESSIONS_REQUEST)
export const retryFailedCompressionsRequest = createAction(RETRY_FAILED_COMPRESSIONS_REQUEST)
export const rejectFailedCompressionsRequest = createAction(REJECT_FAILED_COMPRESSIONS_REQUEST)
export const refreshCompressionsRequest = createAction(REFRESH_COMPRESSIONS_REQUEST)
export const updateImages = createAction(UPDATE_IMAGES, 'images')
export const setBatchCompressionParams = createAction(SET_BATCH_COMPRESSION_PARAMS, 'payload')
export const setIdsToCompress = createAction(SET_IDS_TO_COMPRESS, 'idsToCompress')
export const setSelectedSuccessIds = createAction(SET_SELECTED_SUCCESS_IDS, 'selectedSuccessIds')
export const setSelectedFailedIds = createAction(SET_SELECTED_FAILED_IDS, 'selectedFailedIds')
export const emptyIdsToCompress = createAction(EMPTY_IDS_TO_COMPRESS)
export const emptySelectedSuccessIds = createAction(EMPTY_SELECTED_SUCCESS_IDS)
export const emptySelectedFailedIds = createAction(EMPTY_SELECTED_FAILED_IDS)
export const toggleIdToCompress = createAction(TOGGLE_ID_TO_COMPRESS, 'id')
export const toggleSelectedSuccessId = createAction(TOGGLE_SELECTED_SUCCESS_ID, 'id')
export const toggleSelectedFailedId = createAction(TOGGLE_SELECTED_FAILED_ID, 'id')
export const emptyAvailableImages = createAction(EMPTY_AVAILABLE_IMAGES)
export const emptySuccessCompressions = createAction(EMPTY_SUCCESS_COMPRESSIONS)
export const emptyFailedCompressions = createAction(EMPTY_FAILED_COMPRESSIONS)
export const selectAllAvailableImages = createAction(SELECT_ALL_AVAILABLE_IMAGES)
export const selectAllSuccessCompressions = createAction(SELECT_ALL_SUCCESS_COMPRESSIONS)
export const selectAllFailedCompressions = createAction(SELECT_ALL_FAILED_COMPRESSIONS)
