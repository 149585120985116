import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OtherCompressions from './OtherCompressions'

const mapStateToProps = state => (
  { applyingCompressions: state.compression.applyingCompressions }
)

class ApplyingCompressions extends Component {
  render = () => (
    <OtherCompressions
      compressions={this.props.applyingCompressions}
      caption='Applying'
      className='compression-group-applying'
    />
  )
}

export default connect(mapStateToProps, null)(ApplyingCompressions)
