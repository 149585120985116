import { patch, destroy, post } from './utils'

// TODO: Remove this method after we add api_scope to BaseLink model (:blog_posts, :pages)
const updateLinkPath = link => `/links/${link.type}s/${link.id}`

export async function updateLink(current, { original_href, inner_html }) {
  const params = { link: { original_href, inner_html } }
  const path = updateLinkPath(current) // TODO: use Routes.links_path(current) in future
  const response = await patch(path, params)
  return response.data
}

export async function deleteLink(current) {
  const path = updateLinkPath(current) // TODO: use Routes.links_path(current) in future
  const response = await destroy(path)
  return response.data
}

export async function ignoreLink(params) {
  const path = Routes.links_ignored_urls_path()
  const response = await post(path, params)
  return response.data
}

export async function ignoreDomain(params) {
  const path = Routes.links_ignored_domains_path()
  const response = await post(path, params)
  return response.data
}
