import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setBatchCompressionParams } from '../../actions/compression'
import ImageFormatSelect from '../inputs/image_format_select'
import SizeInput from '../inputs/size_input'
import RangeInput from '../inputs/range_input'

const mapStateToProps = state => ({ batchParams: state.compression.batchParams })

const mapDispatchToProps = { setBatchCompressionParams }

class CommonSettingsBar extends Component {
  static propTypes = {
    setBatchCompressionParams: PropTypes.func.isRequired,
    batchParams: PropTypes.shape({
      quality: PropTypes.number.isRequired,
      maxWidth: PropTypes.number.isRequired,
      maxHeight: PropTypes.number.isRequired,
      format: PropTypes.shape({
        type: PropTypes.string.isRequired,
        indexed: PropTypes.bool, /* for PNG */
      })
    }).isRequired,
  }

  renderSizeSettings = () => {
    const { batchParams, setBatchCompressionParams } = this.props

    return(
      <Fragment>
        <label>{I18n.t('image.compressions.max_width_and_height')}:</label>
        <div className='form-row'>
          <div className='col'>
            <SizeInput
              value={batchParams.maxWidth}
              onChange={maxWidth => setBatchCompressionParams({ maxWidth })}
              max={10000}
              placeholder={I18n.t('captions.width')}
            />
          </div>
          <span className='m-auto'>x</span>
          <div className='col'>
            <SizeInput
              value={batchParams.maxHeight}
              onChange={maxHeight => setBatchCompressionParams({ maxHeight })}
              max={10000}
              placeholder={I18n.t('captions.height')}
            />
          </div>
        </div>
      </Fragment>
    )
  }

  renderQualitySettings = () => {
    const { batchParams: { format, quality }, setBatchCompressionParams } = this.props

    const indexed = format && format.type === 'PNG' /* for png quality is set to 00 */
    const qualityLabel = indexed
      ? I18n.t('image.compressions.quality_for_png')
      : I18n.t('image.compressions.quality_percent', { quality })

    return(
      <Fragment>
        <label className="mt-2">{qualityLabel}</label>
        <div className='form-row'>
          <RangeInput
            disabled={indexed}
            placeholder={I18n.t('image.placeholder.quality')}
            value={quality}
            onChange={quality => setBatchCompressionParams({ quality })}
          />
        </div>
      </Fragment>
    )
  }

  renderFormatSettings = () => {
    return (
      <ImageFormatSelect
        onChange={format => this.props.setBatchCompressionParams({ format })}
        value={this.props.batchParams.format}
      />
    )
  }

  render = () => {
    return (
      <Fragment>
        {this.renderSizeSettings()}
        {this.renderQualitySettings()}
        {this.renderFormatSettings()}
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonSettingsBar)
