import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap4-modal'
import PropTypes from 'prop-types'

export default class Confirmation extends Component {
  static propTypes = {
    btnText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      visible: false
    }
  }

  handleShow = () => { this.setState({ visible: true } ) }

  handleHide = () => { this.setState({ visible: false }) }

  handleAccept = () => {
    this.handleHide()
    this.props.onConfirm()
  }

  get closeButton() {
    return(
      <button type='button' className='btn btn-light' onClick={this.handleHide}>
        {I18n.t('actions.close')}
      </button>
    )
  }

  get confirmButton() {
    const { waiting, applied, compressed_image } = this.state
    return(
      <button type='button' className='btn btn-primary' onClick={this.handleAccept}>
        {I18n.t('actions.accept')}
      </button>
    )
  }

  get modalHeader() {
    return(
      <Fragment>
        <h5 className='modal-title'>Are you sure?</h5>
      </Fragment>
    )
  }

  get modalFooter() {
    return(
      <Fragment>
        {this.closeButton}
        {this.confirmButton}
      </Fragment>
    )
  }

  render() {
    const { btnText, className, children, style } = this.props

    return (
      <Fragment>
        <button type='button' className={className} onClick={this.handleShow} style={style}>
          {btnText}
        </button>
        <span style={{display: 'inline-block'}}>
          <Modal visible={this.state.visible} dialogClassName='confirmation-dialog'>
            <div className='modal-header'>{this.modalHeader}</div>
            <div className='modal-body'>{children}</div>
            <div className='modal-footer'>{this.modalFooter}</div>
          </Modal>
        </span>
      </Fragment>
    );
  }
}
