import {
  UPDATE_CARD_REQUEST_ENDED,
  SHOW_CARD_EDIT_FORM,
  HIDE_CARD_EDIT_FORM
} from '../actions/payment'

const initialState = {
  card: {},
  isCardEditFormActive: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CARD_REQUEST_ENDED:
      return { ...state, card: action.card }
    case SHOW_CARD_EDIT_FORM:
      return { ...state, isCardEditFormActive: true }
    case HIDE_CARD_EDIT_FORM:
      return { ...state, isCardEditFormActive: false }
    default:
      return state
  }
}
