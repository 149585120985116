import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

export default class PostInfo extends Component {
  static propTypes = {
    url: PropTypes.string,
    title: PropTypes.string
  }

  render = () => {
    const { url, title } = this.props

    return url && <span>
      Post:&nbsp;
      <a href={url} target='_blank'>{title && title.length > 0 && title || url}</a>
      <br/>
    </span> || null
  }
}
