import { get, post, patch, destroy } from './utils'

export async function getCompression(id) {
  const path = Routes.images_compression_path(id)
  const response = await get(path)
  return response.data.compressions && response.data.compressions[0]
}

export async function createCompressions(compressionsParams) {
  const params = { compressions: compressionsParams }
  const path = Routes.images_compressions_path()
  const response = await post(path, params)
  return response.data
}

export async function applyCompressions(idOrIds) {
  const path = Routes.images_compression_path(idOrIds)
  const response = await patch(path, { apply: true })
  return response.data
}

export async function retryCompressions(idOrIds) {
  const path = Routes.images_compression_path(idOrIds)
  const response = await patch(path, { retry: true })
  return response.data
}

export async function destroyCompressions(idOrIds) {
  const path = Routes.images_compression_path(idOrIds)
  const response = await destroy(path)
  return response.data
}
