import axios from 'axios'
import ReactOnRails from 'react-on-rails'

const createApiInstance = () => axios.create({
  headers: ReactOnRails.authenticityHeaders({'Content-Type': 'application/json'})
})

export const get = (...args) => createApiInstance().get(...args)
export const post = (...args) => createApiInstance().post(...args)
export const patch = (...args) => createApiInstance().patch(...args)
export const destroy = (...args) => createApiInstance().delete(...args)
