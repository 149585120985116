import { combineReducers } from 'redux'
import { reducer as reduxAutoloaderReducer } from 'redux-autoloader';
import compression from './compression'
import payment from './payment'

export default combineReducers({
  compression,
  payment,
  reduxAutoloader: reduxAutoloaderReducer
})
