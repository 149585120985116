import { all } from 'redux-saga/effects'
import { watchCompressionUpdates } from './compression'
import { watchPaymentUpdates } from './payment'

export default function* rootSaga(context) {
  yield all([
    watchCompressionUpdates(context),
    watchPaymentUpdates(context)
  ])
}
