import React from 'react'
import { CardElement } from 'react-stripe-elements'

const ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

const CardSection = (props) => (
  <div style={{margin: '10px 0px'}}>
    <div className={props.error && 'is-invalid' || null}>
      <CardElement {...ELEMENT_OPTIONS} />
    </div>
    <small className='form-text text-danger'>{props.error}</small>
  </div>
)

export default CardSection;
