import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OtherCompressions from './OtherCompressions'

const mapStateToProps = state => (
  { workingCompressions: state.compression.workingCompressions }
)

class WorkingCompressions extends Component {
  render = () => (
    <OtherCompressions
      compressions={this.props.workingCompressions}
      caption='In progress'
      className='compression-group-working'
    />
  )
}

export default connect(mapStateToProps, null)(WorkingCompressions)
