import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap4-modal'
import PropTypes from 'prop-types'

export default class IgnoreButton extends Component {
  static propTypes = {
    onIgnore: PropTypes.func.isRequired,
    onDomainIgnore: PropTypes.func.isRequired,
    url: PropTypes.string,
    domain: PropTypes.string
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      visible: false
    }
  }

  handleShow = () => { this.setState({ visible: true } ) }

  handleHide = () => { this.setState({ visible: false }) }

  handleIgnore = () => {
    this.handleHide()
    this.props.onIgnore()
  }

  handleDomainIgnore = () => {
    this.handleHide()
    this.props.onDomainIgnore()
  }

  get closeButton() {
    return(
      <button type='button' className='btn btn-light' onClick={this.handleHide}>
        {I18n.t('actions.close')}
      </button>
    )
  }

  get ignoreButton() {
    return(
      <button type='button' className='btn btn-primary' onClick={this.handleIgnore}>
        {I18n.t('actions.ignore')}
      </button>
    )
  }

  get ignoreDomainButton() {
    return(
      <button type='button' className='btn btn-primary' onClick={this.handleDomainIgnore}>
        {I18n.t('actions.ignore_domain')}
      </button>
    )
  }

  get modalHeader() {
    return(
      <h5 className='modal-title'>{I18n.t('are_you_sure')}</h5>
    )
  }

  get modalBody() {
    const { url, domain } = this.props
    return(
      I18n.t('link.ignore_message', { url: url, domain: domain })
    )
  }

  get modalFooter() {
    return(
      <Fragment>
        {this.closeButton}
        {this.ignoreButton}
        {this.ignoreDomainButton}
      </Fragment>
    )
  }

  render() {
    return (
      <Fragment>
        <button type='button' className='btn btn-sm btn-dark' onClick={this.handleShow}>
          {I18n.t('actions.ignore')}
        </button>
        <span style={{display: 'inline-block'}}>
          <Modal visible={this.state.visible} dialogClassName='confirmation-dialog'>
            <div className='modal-header'>{this.modalHeader}</div>
            <div className='modal-body text-break'>{this.modalBody}</div>
            <div className='modal-footer'>{this.modalFooter}</div>
          </Modal>
        </span>
      </Fragment>
    );
  }
}
