import 'core-js/fn/array/flat-map'

const groupByCompressionStatus = (images) => {
  let result = {}
  images.forEach(image =>
    image.compressions.forEach(compression => {
      result[compression.status] = result[compression.status] || []
      result[compression.status].push(compression)
    })
  )
  return result
}

export function groupImages(images) {
  const grouped = groupByCompressionStatus(images)

  const available = images.filter(i => {
    return i.compressions.length === 0 ||
           i.compressions.every(x => x.applying_status === 'success')
  })
  const complete = (grouped.complete || []).filter(i => i.applying_status == null)
  const applying = (grouped.complete || []).filter(i => i.applying_status == 'working')
  const failed = [...(grouped.failed || []), ...(grouped.interrupted || [])]
  const failedToApply = images.flatMap(i => i.compressions.filter(x => x.applying_status === 'failed'))
  const working = grouped.working || []
  const queued = [...(grouped.queued || []), ...(grouped[null] || [])]

  return { available, complete, applying, failed, failedToApply, working, queued }
}

export function availableIds(current, available) {
  return current.filter(id => available.map(f => f.id).includes(id))
}
