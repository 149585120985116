import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap4-modal'
import PropTypes from 'prop-types'
import { compressImage, applyImage, updateImage } from '../api/image'
import LoaderCircle from './loader_circle'
import ErrorsList from './errors_list'
import ImageCard from './image_card'
import ImageFormatSelect from './inputs/image_format_select'
import SizeInput from './inputs/size_input'
import RangeInput from './inputs/range_input'

function getImageFormat(image) {
  return { type: image.type.replace('image/', '').toUpperCase(), indexed: false }
}

export default class Compression extends Component {
  static propTypes = {
    image: PropTypes.object.isRequired,
    compressedImage: PropTypes.object,
    onApply: PropTypes.func,
    closeOnAction: PropTypes.bool,
    compression: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    const { image, image: { width, height }, compressedImage, compression } = props
    this.state = {
      visible: false,
      waiting: false,
      applied: false,
      errors: [],
      image,
      compressedImage,
      compression_id: compression && compression.id,
      format: getImageFormat(image),
      width,
      height,
      quality: 100,
      sizePercentage: 100
    }
  }

  scaleWidth = (value) => {
    const {image: { width, height } } = this.props
    let newHeight = value
    if (newHeight === '' || newHeight < 1) newHeight = 1
    else if (newHeight > height) newHeight = height
    newHeight = parseInt(newHeight)
    let newWidth = ~~(newHeight * width / height)
    if (newWidth < 1) newWidth = 1
    this.setState({
      height: newHeight,
      width: newWidth,
      sizePercentage: ~~((newWidth / width + newHeight / height) * 50)
    })
  }

  scaleHeight = (value) => {
    const {image: { width, height } } = this.props
    let newWidth = value
    if (newWidth === '' || newWidth < 1) newWidth = 1
    else if (newWidth > width) newWidth = width
    newWidth = parseInt(newWidth)
    let newHeight = ~~(newWidth * height / width)
    if (newHeight < 1) newHeight = 1
    this.setState({
      height: newHeight,
      width: newWidth,
      sizePercentage: ~~((newWidth / width + newHeight / height) * 50)
    })
  }

  handleShow = (e) => {
    this.setState({ visible: true } )
    e.stopPropagation()
  }

  handleHide = () => { this.setState({ visible: false }) }

  handleSizePercentageChange = (percent) => {
    const {image: { width, height } } = this.props
    if (width > height) {
      this.scaleHeight(width * percent / 100)
    }
    else {
      this.scaleWidth(height * percent / 100)
    }
  }

  handleQualityChange = (quality) => {
    this.setState({ quality })
  }

  handleCompress = async () => {
    const { image, width, height, quality, format } = this.state
    this.setState({ waiting: true, applied: false })
    const params = { image_id: image.id, width, height, quality, format }
    if (this.props.closeOnAction) {
      this.setState({ visible: false })
      compressImage(params)
      return
    }
    const result = await compressImage(params)
    const { compressed_image, errors } = result
    this.setState({
      waiting: false,
      compression_id: result.id,
      compressedImage: compressed_image,
      errors
    })
  }

  handleApply = async () => {
    this.setState({ waiting: true })
    const { compression_id } = this.state
    if (this.props.closeOnAction) {
      this.setState({ visible: false })
      applyImage({ compression_id })
      return
    }
    const result = await applyImage({ compression_id })
    this.setState({ waiting: false, applied: true, visible: false })
    const { onApply } = this.props
    onApply && onApply(result)
  }

  get errors() {
    const { errors } = this.state
    return(
      <ErrorsList
        message='Errors:'
        errors={errors}
        visible={errors.length !== 0}
      />
    )
  }

  get sizeSettingsFragment() {
    const { image: { width, height, src } } = this.props

    return(
      <Fragment>
        <label>{I18n.t('image.compressions.size_percent', { size: this.state.sizePercentage })}</label>
          <RangeInput
            placeholder={I18n.t('image.placeholder.size')}
            value={this.state.sizePercentage}
            onChange={this.handleSizePercentageChange}
          />
        <div className='form-row'>
          <div className='col'>
            <SizeInput
              value={this.state.width}
              onChange={this.scaleHeight}
              max={width}
              placeholder={I18n.t('captions.width')}
            />
          </div>
          <span className='m-auto'>x</span>
          <div className='col'>
            <SizeInput
              value={this.state.height}
              onChange={this.scaleWidth}
              max={height}
              placeholder={I18n.t('captions.height')}
            />
          </div>
        </div>
      </Fragment>
    )
  }

  get qualitySettingsFragment() {
    return(
      <Fragment>
        <label>{I18n.t('image.compressions.quality_percent', { quality: this.state.quality })}</label>
        <div className='form-row'>
          <RangeInput
            placeholder={I18n.t('image.placeholder.quality')}
            value={this.state.quality}
            onChange={this.handleQualityChange}
          />
        </div>
      </Fragment>
    )
  }

  get closeButton() {
    return(
      <button type='button' className='btn btn-light' onClick={this.handleHide}>
        {I18n.t('actions.close')}
      </button>
    )
  }

  get compressButton() {
    return(
      !this.state.waiting &&
      <button type='button' className='btn btn-primary' onClick={this.handleCompress}>
        {I18n.t('actions.compress')}
      </button>
    )
  }

  get applyButton() {
    const { waiting, applied, compressedImage } = this.state
    return(
      !waiting && !applied && compressedImage && compressedImage.size &&
      <button type='button' className='btn btn-success' onClick={this.handleApply}>
        {I18n.t('actions.apply')}
      </button>
    )
  }

  get modalHeader() {
    return(
      <Fragment>
        <h5 className='modal-title'>{I18n.t('image.compressions.caption')}</h5>
      </Fragment>
    )
  }

  get formatSelector() {
    return (
      <ImageFormatSelect
        onChange={format => this.setState({ format })}
        value={this.state.format}
      />
    )
  }

  get modalBody() {
    if (this.state.waiting) return (
      <Fragment>
        <div className='alert alert-info'>
          If it takes a long time, you can close this modal and see the result
          later on the <a href={Routes.images_compressions_path()}>
            Compressions page
          </a>.
        </div>
        <LoaderCircle />
      </Fragment>
    )

    const { image, compressedImage, format } = this.state
    const enableQualitySetting = format && format.type === 'JPEG'
    return(
      <Fragment>
        {this.errors}
        <div className='row'>
          <div className='col-sm-6'>
            <ImageCard
              image={image}
              title={I18n.t('image.current')}
            />
          </div>
          <div className='col-sm-6'>
            <ImageCard
              image={compressedImage}
              title={I18n.t('image.compressed')}
            />
          </div>
        </div>
        <br />
        {this.sizeSettingsFragment}
        {enableQualitySetting && this.qualitySettingsFragment}
        {this.formatSelector}
      </Fragment>
    )
  }

  get modalFooter() {
    return(
      <Fragment>
        {this.closeButton}
        {this.compressButton}
        {this.applyButton}
      </Fragment>
    )
  }

  get compressActionButton() {
    return (
      <button type='button' className='btn btn-secondary' onClick={this.handleShow} style={{zIndex: 1}}>
        {I18n.t('actions.compress')}
      </button>
    )
  }

  get clickableArea() {
    const { style, children } = this.props
    return <div onClick={this.handleShow} style={{zIndex: 1, ...style}}>{children}</div>
  }

  render() {
    console.log("image type ", this.state.image.type)
    return (
      <Fragment>
        {this.props.children && this.clickableArea || this.compressActionButton}
        <Modal visible={this.state.visible} dialogClassName='modal-dialog modal-lg'>
          <div className='modal-header'>{this.modalHeader}</div>
          <div className='modal-body'>{this.modalBody}</div>
          <div className='modal-footer'>{this.modalFooter}</div>
        </Modal>
      </Fragment>
    );
  }
}
