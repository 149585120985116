import { select, call, put, takeEvery, all } from 'redux-saga/effects'
import * as PaymentApi from '../api/payment'
import {
  UPDATE_CARD_REQUEST,
  updateCardRequestEnded,
  hideCardEditForm
} from '../actions/payment'

function* updateCard(action) {
  const { token, error } = yield call(action.stripeInstance.createToken)
  if (token) {
    const result = yield call(PaymentApi.updateSource, { token: token.id })
    yield put(updateCardRequestEnded({ last4: result.profile.last_digits, error: result.error }))
    yield put(hideCardEditForm())
  } else {
    yield put(updateCardRequestEnded({ error: error.message }))
  }
}

export function* watchPaymentUpdates() {
  yield all([
    takeEvery(UPDATE_CARD_REQUEST, updateCard)
  ])
}
