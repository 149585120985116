import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { humanSizeInKb, humanSizeInPixels } from '../../utils/size.utils'

import CompressionGroup from './CompressionGroup'
import CompressionCard from './CompressionCard'
import PostInfo from './PostInfo'

const mapStateToProps = state => (
  { failedToApply: state.compression.failedToApply }
)

class FailedToApplyCompressions extends Component {
  renderItem = (compression) => {
    const original = compression.image
    return (
      <CompressionCard key={original.id} original={original} showMenu={true}>
        {compression.errors.length > 0 &&
        <span
          className='text-danger'
          style={{display: 'block', lineHeight: 'normal'}}
        >
          {compression.errors[0]}
        </span>}
        <PostInfo url={original.post_url} title={original.post_title}/>
        {[
          humanSizeInPixels(original.width, original.height),
          humanSizeInKb(original.size),
        ].filter(i => i).join(', ')}
      </CompressionCard>
    )
  }

  render = () => {
    const { failedToApply } = this.props
    if (failedToApply.length === 0) return null
    return (
      <CompressionGroup caption='Failed to replace' className='compression-group-failed'>
        {failedToApply.map(this.renderItem)}
      </CompressionGroup>
    )
  }
}

export default connect(mapStateToProps, null)(FailedToApplyCompressions)
