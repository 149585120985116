import { createAction } from './utils'

export const UPDATE_CARD_REQUEST = 'UPDATE_CARD_REQUEST'
export const UPDATE_CARD_REQUEST_ENDED = 'UPDATE_CARD_REQUEST_ENDED'
export const SHOW_CARD_EDIT_FORM = 'SHOW_CARD_EDIT_FORM'
export const HIDE_CARD_EDIT_FORM = 'HIDE_CARD_EDIT_FORM'

export const updateCardRequest = createAction(UPDATE_CARD_REQUEST, 'stripeInstance')
export const updateCardRequestEnded = createAction(UPDATE_CARD_REQUEST_ENDED, 'card')
export const showCardEditForm = createAction(SHOW_CARD_EDIT_FORM)
export const hideCardEditForm = createAction(HIDE_CARD_EDIT_FORM)
