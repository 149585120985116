import React from 'react'
import PropTypes from 'prop-types'

const formats = [
  { name: I18n.t('image.compressions.leave_as_is'), value: 'AS_IS' },
  { name: 'JPEG', value: 'JPEG' },
  { name: 'PNG', value: 'PNG' },
  { name: 'GIF', value: 'GIF' },
]

const ImageFormatSelect = (props) => {
  const format = props.value;
  const isPng = format && format.type === 'PNG';

  const handleSelectChange = (e) => {
    const type = e.currentTarget.value
    props.onChange(type === 'AS_IS' ? null : { type, indexed: false })
  }

  const handleCheckboxChange = (e) => {
    const indexed = e.currentTarget.checked
    props.onChange({ type: props.value.type, indexed })
  }

  return(
    <>
      <label className="d-block mt-2">
        <span>{I18n.t('image.compressions.format')}:</span>
        <select
          className='mt-1 w-100'
          name="image_format"
          onChange={handleSelectChange}
          value={format ? format.type : 'AS_IS'}
        >
          {formats.map(f => <option key={f.value} value={f.value}>{f.name}</option>)}
        </select>
      </label>
      {isPng && (
        <label className="d-block mt-2">
          <input type="checkbox"
            onChange={handleCheckboxChange}
            value={format.indexed}
          />
          {' ' + I18n.t('image.compressions.indexed_color')}
        </label>
      )}
    </>
  )
}

ImageFormatSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    type: PropTypes.string.isRequired,
    indexed: PropTypes.bool.isRequired,
  })
}

export default ImageFormatSelect
