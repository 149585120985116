import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectStripe } from 'react-stripe-elements'

import CardSection from './CardSection'

import {
  updateCardRequest,
  hideCardEditForm
} from '../../actions/payment'

const mapStateToProps = (state) => {
  const { card } = state.payment
  return { card }
}

const mapDispatchToProps = {
  updateCardRequest,
  hideCardEditForm
}

class CheckoutForm extends Component {
  render() {
    const { updateCardRequest, hideCardEditForm, stripe, card: { error } } = this.props

    return (
      <Fragment>
        <CardSection error={error} />
        <button type='button' className='btn btn-primary' onClick={() => updateCardRequest(stripe)}>
          Set card
        </button>
        <button type='button' className='float-right btn btn-link' onClick={hideCardEditForm}>
          Cancel
        </button>
      </Fragment>
    )
  }
}

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(CheckoutForm))
