import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { humanSizeInKb, humanSizeInPixels } from '../../utils/size.utils'

import CompressionGroup from './CompressionGroup'
import CompressionCard from './CompressionCard'
import PostInfo from './PostInfo'

class OtherCompressions extends Component {
  static propTypes = {
    compressions: PropTypes.array.isRequired,
    caption: PropTypes.string.isRequired,
    className: PropTypes.string
  }

  renderItem = (compression) => {
    const original = compression.image
    
    return (
      <CompressionCard original={original} key={original.id}>
          No more than {humanSizeInPixels(compression.width, compression.height)}, quality: {compression.quality}%
          <br/>
          <PostInfo url={original.post_url} title={original.post_title}/>
          {humanSizeInPixels(original.width, original.height)}, {humanSizeInKb(original.size)}
      </CompressionCard>
    )
  }

  render = () => {
    const { compressions, caption, className } = this.props
    if (compressions.length === 0) return null
    return (
      <CompressionGroup caption={caption} className={className}>
        {compressions.map(this.renderItem)}
      </CompressionGroup>
    )
  }
}

export default connect()(OtherCompressions)
