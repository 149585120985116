import React from 'react'

const RangeInput = (props) => {
  return(
    <input
      disabled={props.disabled}
      type='range'
      className='w-100'
      min={0}
      max={100}
      step={1}
      value={props.value}
      onChange={e => props.onChange(Math.ceil(e.target.value))}
      placeholder={props.placeholder}
    />
  )
}

export default RangeInput
