import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { updateLink, deleteLink, ignoreLink, ignoreDomain } from '../api/link'
import LoaderCircle from './loader_circle'
import ErrorsList from './errors_list'
import InputFormGroup from './inputs/input_form_group'
import Confirmation from './confirmation'
import IgnoreButton from './ignore_button'

export default class EditLink extends Component {
  static propTypes = {
    link: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)
    const { link, link: { original_href, inner_html, errors } } = props
    this.state = {
      original_href, inner_html, link, errors,
      showApplyButton: link.is_redirect,
      showSaveButton: false, showAlerts: true, waiting: false
    }
  }

  canShowSaveButton = ({ original_href, inner_html }) => {
    const givenLink = this.state.link
    return givenLink.original_href != original_href
           || givenLink.inner_html != inner_html
  }

  canShowApplyButton = (original_href) => {
    const { link } = this.state

    return link.is_redirect && original_href != link.redirect_url
  }

  handleOriginalHrefChange = (event) => {
    const original_href = event.target.value
    const { inner_html } = this.state
    this.setState({
      original_href,
      showAlerts: false,
      showSaveButton: this.canShowSaveButton({ original_href, inner_html }),
      showApplyButton: this.canShowApplyButton(original_href)
    })
  }

  handleInnerHtmlChange = (event) => {
    const inner_html = event.target.value
    const { original_href } = this.state
    this.setState({
      inner_html,
      showAlerts: false,
      showSaveButton: this.canShowSaveButton({ original_href, inner_html }),
      showApplyButton: this.canShowApplyButton(original_href)
    })
  }

  updateStates = (result) => {
    const { link } = result
    const { original_href, inner_html, errors, destroyed } = (link || {})
    // const mergedErrors = Object.assign(this.state.errors, errors || {})
    this.setState({
      link: Object.assign(this.state.link, link),
      original_href, inner_html,
      waiting: false,
      showAlerts: true,
      message: Object.keys(errors || {}).length === 0 ? I18n.t('link.update.success') : null,
      destroyed,
      errors: errors || {},
      showSaveButton: false,
      showApplyButton: this.canShowApplyButton(original_href)
    })
  }

  handleSave = async () => {
    this.setState({ waiting: true })
    const { link } = this.props
    const { original_href, inner_html } = this.state
    const result = await updateLink(link, { original_href, inner_html })
    this.updateStates(result)
  }

  handleDelete = async () => {
    this.setState({ waiting: true })
    const result = await deleteLink(this.props.link)
    this.updateStates(result)
  }

  handleApply = () => {
    this.setState({
      original_href: this.state.link.redirect_url,
      showApplyButton: false
    })
  }

  handleIgnore = async () => {
    this.setState({ waiting: true })
    const { link: { url } } = this.props
    const result = await ignoreLink({url: url})
    this.updateStates(result)
    this.setState({ ignore: true })
  }

  handleIgnoreDomain = async () => {
    this.setState({ waiting: true })
    const { link: { domain} } = this.props
    const result = await ignoreDomain({ domain: domain })
    window.location.reload();
  }

  get status_code_info() {
    const { link } = this.state

    return(
      <Fragment>
        <span className={`badge ${link.css_class}`}>
          {I18n.t('link.status', { code_info: link.status_info })}
        </span>&nbsp;
        {link.is_https_redirect &&
          <span className='badge badge-info'>HTTP &rarr; HTTPS</span>}&nbsp;
        {link.is_redirect &&
          <span className={`badge ${link.css_class_for_code}`}>
            {I18n.t('link.redirection_status', { code_info: link.redirection_status_info })}
          </span>}
      </Fragment>
    )
  }

  get errors() {
    const { link: { errors }, showAlerts } = this.state
    const baseErrors = errors && errors['base'] || []
    return(
      <ErrorsList
        message={`The following errors are prohibited this link from being saved:`}
        errors={Object.values(baseErrors)}
        visible={Object.values(baseErrors).length !== 0 && showAlerts}
      />
    )
  }

  get message() {
    const { message, showAlerts } = this.state
    return(
      message && showAlerts &&
      <div className='alert alert-success'>
        {message}
      </div>
    )
  }

  get inputs() {
    const { link } = this.state

    return link && (
      <Fragment>
        {this.originalHrefFormGroup}
        {this.innerHtmlFormGroup}
      </Fragment>
    )
  }

  get contextAround() {
    const { link } = this.state

    return link && (
      <div className='form-group'>
        <span className='small form-text text-muted'>
          <b>Context: </b>
          <span className='context-around'>
            ... {link.context_before}&nbsp;
            <a className='badge badge-warning' target='_blank' href={link.url}>
              {link.inner_text}
            </a>
            &nbsp;{link.context_after} ...
          </span>
        </span>
      </div>
    )
  }

  get originalHrefFormGroup() {
    const { link, showAlerts, original_href } = this.state

    return(
      <InputFormGroup
        tooltip={link.is_redirect && I18n.t('link.redirects_to', { url: link.redirect_url })}
        errors={[link.errors.original_href, link.errors.href].flat().filter(e => !!e)}
        showAlerts={showAlerts}
        label='Original href'
        value={original_href || ''}
        onChange={this.handleOriginalHrefChange}
        placeholder={I18n.t('link.placeholder.href')}
      />
    )
  }

  get innerHtmlFormGroup() {
    return(
      <InputFormGroup
        type='textarea'
        errors={this.state.link.errors['inner_html']}
        showAlerts={this.state.showAlerts}
        label='Inner html'
        value={this.state.inner_html || ''}
        onChange={this.handleInnerHtmlChange}
        placeholder={I18n.t('link.placeholder.inner_html')}
      />
    )
  }

  get openButton() {
    const { link } = this.state

    return(
      <Fragment>
        <a href={link.url} target='_blank' className='btn btn-light btn-sm w-100'>
          {I18n.t('link.open')}
        </a>
      </Fragment>
    )
  }

  get applyButton() {
    const { link, link: { ignored } } = this.state
    const { showApplyButton } = this.state

    return(
      (link && showApplyButton && !ignored ) &&
      <Fragment>
        <button
          type='button'
          className='btn btn-secondary btn-sm w-100'
          onClick={this.handleApply}
        >
          {I18n.t('link.apply_redirection')}
        </button>
      </Fragment>
    )
  }

  get deleteButton() {
    const { link } = this.state

    return(
      <Confirmation
        onConfirm={this.handleDelete}
        className='btn btn-sm btn-danger'
        btnText={I18n.t('actions.delete')}
      >
        <div className='alert alert-warning'>
          <small>
            <b>Warning!</b> {I18n.t('link.deletion_warning')}
          </small>
          <a target='_blank' href={link.url} className='btn btn-warning btn-sm'>
            {I18n.t('link.open_in_new_tab')}
          </a>
        </div>
        {I18n.t('link.deletion_tooltip')}
      </Confirmation>
    )
  }

  get saveButton() {
    const { link, link: { errors } } = this.state

    return(
      (link && errors && Object.values(errors).length !== 0 || this.state.showSaveButton) &&
      <button
        type='button'
        className='btn btn-warning btn-sm'
        onClick={this.handleSave}
      >
        {I18n.t('actions.save')}
      </button>
    )
  }

  get ignoreButton() {
    const { link, link: { url, domain } } = this.state

    return(
      link &&
      <IgnoreButton
        onIgnore={this.handleIgnore}
        onDomainIgnore={this.handleIgnoreDomain}
        url={url}
        domain={domain}
      />
    )
  }

  get destroyedMessage() {
    const { link: { original_href } } = this.state
    const href = original_href.length > 53 ? `${original_href.slice(0, 50)}...` : original_href

    return(
      <div className='alert alert-secondary'>
        {I18n.t('link.destroy.success', { href: href })}
      </div>
    )
  }

  get ignoreMessage() {
    const { link: { original_href, ignored } } = this.state
    const href = original_href.length > 53 ? `${original_href.slice(0, 50)}...` : original_href

    return(
      <div className='alert alert-success'>
        {I18n.t('link.ignore.success.ignore', { href: href })}
      </div>
    )
  }

  render() {
    if (this.state.destroyed) return this.destroyedMessage
    if (this.state.ignore) return this.ignoreMessage
    if (this.state.waiting) return <LoaderCircle />
    return(
      <Fragment>
        {this.status_code_info}
        <hr />
        <div className='row'>
          <div className='col-sm-10'>
            {this.errors}
            {this.message}
            {this.inputs}
            {this.contextAround}
          </div>
          <div className='col-sm-2'>
            <div className='w-100 btn-group-vertical'>
              {this.openButton}
              {this.applyButton}
              {this.deleteButton}
              {this.saveButton}
              {this.ignoreButton}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
