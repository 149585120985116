import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { StripeProvider, Elements } from 'react-stripe-elements'

import InjectedCheckoutForm from './CheckoutForm'

import { showCardEditForm } from '../../actions/payment'

const mapStateToProps = (state, ownProps) => {
  const { isCardEditFormActive, card: { last4 } } = state.payment
  return {
    isCardEditFormActive,
    last4,
    initialLast4: ownProps.card.last4
  }
}

const mapDispatchToProps = { showCardEditForm }

class Payments extends Component {
  get actualLast4() {
    const { initialLast4, last4 } = this.props
    return last4 || initialLast4
  }

  get cardNumberInfo() {
    if (!this.actualLast4) return <span className='text-muted'>NO CARD PROVIDED</span>

    return (
      <Fragment>
        <span className='text-muted'>NUMBER: </span>
        <span className='text'>**** **** **** {this.actualLast4}</span>
      </Fragment>
    )
  }

  get noCardWarning() {
    if (this.actualLast4) return null

    return (
      <div className='alert alert-warning'>
        To make sure you service continues without interruptions, you can enter your <b>credit card details</b> at any time.
        We will not charge you for using {I18n.t('captions.app_name')} until the end of the trial.
      </div>
    )
  }

  get setCardButton() {
    return (
      <button
        type='button'
        className='btn btn-primary'
        onClick={this.props.showCardEditForm}
      >
        SET CREDIT CARD
      </button>
    )
  }

  render = () => {
    const { isCardEditFormActive } = this.props

    if(isCardEditFormActive) return (
      <StripeProvider stripe={this.props.stripe}>
        <Elements>
          <InjectedCheckoutForm />
        </Elements>
      </StripeProvider>
    )

    return(
      <Fragment>
        {this.noCardWarning}
        <div className='row d-flex align-items-center'>
          <div className='col'>{this.cardNumberInfo}</div>
          <div className='col-auto ml-auto'>{this.setCardButton}</div>
        </div>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments)
