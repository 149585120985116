import { get, patch } from './utils'
import { getCompression, createCompressions, applyCompressions } from './compression'
import { askWhile } from '../utils/askWhile'

// TODO: Remove this method after we add api_scope to BaseImage model (:blog_posts, :pages)
const updateImagePath = image => `${window.location.pathname}/${image.id}`

export async function getImages(ids) {
  if (ids.length === 0) return []
  const path = Routes.api_v1_image_path(ids)
  const response = await get(path)
  return response.data
}

export async function compressImage(params) {
  const compressions = await createCompressions([params])
  const id = compressions.compressions_ids[0]
  const request = async () => await getCompression(id)
  const condition = (data) => !['complete', 'failed', 'interrupted'].includes(data.status)
  return await askWhile(request, condition)
}

export async function applyImage({ compression_id }) {
  await applyCompressions(compression_id)
  const request = async () => await getCompression(compression_id)
  const condition = (data) => data && [null, 'working'].includes(data.applying_status)
  return await askWhile(request, condition)
}

export async function updateImage(current, { image, keep_old = false, force = false }) {
  const params = { image: Object.assign(image, { keep_old, force }) }
  const path = updateImagePath(current) // TODO: use Routes.images_path(current) in future
  const response = await patch(path, params)
  return response.data
}

export async function safeRefreshImages(images) {
  const refreshed = await getImages(images.map(i => i.id))
  return images.map(o => Object.assign(o, refreshed.find(i => i.id === o.id)))
}
