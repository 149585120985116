import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { humanSizeInKb, humanSizeInPixels } from '../../utils/size.utils'

import CompressionGroup from './CompressionGroup'
import CompressionCard from './CompressionCard'
import PostInfo from './PostInfo'

import {
  retryFailedCompressionsRequest,
  rejectFailedCompressionsRequest,
  selectAllFailedCompressions,
  emptySelectedFailedIds,
  toggleSelectedFailedId
} from '../../actions/compression'

const mapStateToProps = state => {
  const { selectedFailedIds, failedCompressions } = state.compression
  return { selectedFailedIds, failedCompressions }
}

const mapDispatchToProps = {
  retryFailedCompressionsRequest,
  rejectFailedCompressionsRequest,
  selectAllFailedCompressions,
  emptySelectedFailedIds,
  toggleSelectedFailedId
}

class FailedCompressions extends Component {
  renderItem = (compression) => {
    const original = compression.image
    const { selectedFailedIds, toggleSelectedFailedId } = this.props

    return (
      <CompressionCard
        key={original.id}
        original={original}
        showMenu={true}
        onChange={() => toggleSelectedFailedId(compression.id)}
        checked={selectedFailedIds.includes(compression.id)}
        selectable={true}
      >
        <PostInfo url={original.post_url} title={original.post_title}/>
        {[
          humanSizeInPixels(original.width, original.height),
          humanSizeInKb(original.size),
        ].filter(i => i).join(', ')}
      </CompressionCard>
    )
  }

  renderButtons = () => {
    const {
      selectedFailedIds, emptySelectedFailedIds, selectAllFailedCompressions,
      retryFailedCompressionsRequest, rejectFailedCompressionsRequest
    } = this.props

    return (
      <Fragment>
        <button type='button' className='btn btn-sm btn-secondary' onClick={selectAllFailedCompressions}>Select All</button>&nbsp;
        <button type='button' className='btn btn-sm btn-secondary' onClick={emptySelectedFailedIds}>Unselect All</button>&nbsp;
        {selectedFailedIds.length > 0 && <Fragment>
          <button type='button' className='btn btn-sm btn-success' onClick={retryFailedCompressionsRequest}>Retry selected</button>&nbsp;
          <button type='button' className='btn btn-sm btn-danger' onClick={rejectFailedCompressionsRequest}>Reject selected</button>&nbsp;
        </Fragment>}
      </Fragment>
    )
  }

  render = () => {
    const { failedCompressions } = this.props
    if (failedCompressions.length === 0) return null
    return (
      <CompressionGroup
        caption='Failed to compress'
        className='compression-group-failed'
        buttons={this.renderButtons()}
       >
        {failedCompressions.map(this.renderItem)}
      </CompressionGroup>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FailedCompressions)
