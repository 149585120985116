import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { SocialIcon } from 'react-social-icons';
import Pluralize from 'react-pluralize'
import Moment from 'moment'
import classNames from 'classnames'

import SocialShare from './social_share'
import Confirmation from './confirmation'
import { hideSocialPost } from '../api/social_post'

export default class SocialPost extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)
    const { post: { deleted_at } } = props
    this.state = { isHidden: !!deleted_at }
  }

  renderDomainRank = () => {
    const { post } = this.props
    let domainRank = '-'
    if (post.domain_authority && post.domain_authority > 0) {
      domainRank = post.domain_authority
    }
    return (
      <Fragment>
        <span className='badge domain-authority'>DA: {domainRank}</span>
      </Fragment>
    )
  }

  socialButton = (network, count) => {
    return (
      <span title={network} style={{marginRight: '5px'}}>
        <SocialIcon network={network} style={{width: '18px', height: '18px', marginTop: '-1px'}} />
        <span className='badge badge-light'>{count}</span>
      </span>
    )
  }

  renderSocialShares = () => {
    const { post } = this.props

    return (
      <Fragment>
        <span className='badge text-secondary' style={{marginRight: '5px', marginLeft: '-3px'}}>Shares:</span>
        {this.socialButton('facebook', post.facebook_shares)}
        {this.socialButton('linkedin', post.linkedin_shares)}
        <span className='badge text-secondary'>
          <span className='total-shares'>{post.shares_count}</span>
          &nbsp;total,
        </span>
      </Fragment>
    )
  }

  renderPostedAt = () => {
    const { post } = this.props
    const networks = post.affected_social_media_channels.map(i => i.social_network)

    if (networks.length !== 0) return (
      <Fragment>
        <span style={{marginLeft: '5px'}} className='badge text-success'>
          Posted at: <span className='posted-networks'>{networks.join(', ')}</span>
        </span>
      </Fragment>
    )
  }

  renderHideLink = () => {
    return (
      <Confirmation
        btnText={I18n.t('actions.hide')}
        onConfirm={this.handleHide}
        className='btn btn-sm btn-link text-danger'
        style={{ padding: '0px 0px 1px 0px' }}
      >
        {I18n.t('social.hide_message')}
      </Confirmation>
    )
  }

  handleHide = async () => {
    const { post: { id } } = this.props
    this.setState({ isDisabled: true })
    await hideSocialPost(id)
    this.setState({ isHidden: true })
  }

  render() {
    const { post } = this.props
    const { isHidden, isDisabled } = this.state

    if (isHidden) return null

    return(
      <div className={classNames({ 'disabled-content': isDisabled })}>
        <div className='border rounded p-3'>
          <div className='row'>
            <div className='col-sm-2' style={{width: '100px'}}>
              <img style={{marginRight: '5px'}} className='rounded w-100' src={post.featured_image} />
            </div>
            <div className='col-sm-10'>
              <b><a className='text-dark' target='_blank' href={post.url}>{post.title}</a></b>&nbsp;
              <span className='badge badge-secondary'>
                <Pluralize singular='word' count={post.words_count} />
              </span>
              <span className='badge text-secondary'>{new Moment(post.published_at).format('MMM DD, h:mm A')}</span>
              {this.renderHideLink()}
              <br/>
              <span className='text-secondary'>
                {post.meta_description}
              </span>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-11'>
              {this.renderSocialShares()}
              <span style={{marginLeft: '5px'}} className='badge text-secondary'>
              Domain:&nbsp;
              <span className='text-muted'><i className='post-domain'>{post.domain}</i></span>
              </span>
              {this.renderDomainRank()}
              {this.renderPostedAt()}
            </div>
            <div className='col-sm-1'>
              <span className='float-right' style={{marginTop: '-7px'}}>
                <SocialShare post={post} />
              </span>
            </div>
          </div>
        </div>
        <br />
      </div>
    )
  }
}
