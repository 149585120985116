import React from 'react'

const ErrorsList = (props) => {
  const errorsList = props.errors.map((error, index) => {
    return <li key={index}>{error}</li>
  })
  return(
    props.visible &&
    <div className='alert alert-danger'>
      <h5>{props.message}</h5>
      <ul>{errorsList}</ul>
    </div>
  )
}

export default ErrorsList
