import { select, call, put, takeEvery, all } from 'redux-saga/effects'
import * as BatchCompressionApi from '../api/compression'
import * as ImageApi from '../api/image'
import {
  REFRESH_COMPRESSIONS_REQUEST,
  CREATE_COMPRESSIONS_REQUEST,
  APPLY_SUCCESS_COMPRESSIONS_REQUEST,
  REJECT_SUCCESS_COMPRESSIONS_REQUEST,
  RETRY_FAILED_COMPRESSIONS_REQUEST,
  REJECT_FAILED_COMPRESSIONS_REQUEST,
  emptyAvailableImages,
  emptySuccessCompressions,
  emptyFailedCompressions,
  updateImages
} from '../actions/compression'

export function* refreshCompressions() {
  const { allImages } = yield select(state => state.compression)
  const refreshed = yield call(ImageApi.safeRefreshImages, allImages)
  yield put(updateImages(refreshed))
}

export function* createCompressions() {
  const { allImages, batchParams, idsToCompress } = yield select(state => state.compression)
  const params =
    idsToCompress
      .map(id => {
        const image = allImages.find(i => i.id == id)
        return {
          image_id: id,
          width: Math.min(...[image.width, batchParams.maxWidth].filter(i => i)),
          height: Math.min(...[image.height, batchParams.maxHeight].filter(i => i)),
          quality: batchParams.quality,
          format: batchParams.format,
        }
      })
  yield call(BatchCompressionApi.createCompressions, params)
  yield put(emptyAvailableImages())
}

function* processSuccessCompressions(apiAction) {
  const { selectedSuccessIds } = yield select(state => state.compression)
  yield call(apiAction, selectedSuccessIds)
  yield put(emptySuccessCompressions())
}

export function* applySuccessCompressions() {
  yield* processSuccessCompressions(BatchCompressionApi.applyCompressions)
}

export function* rejectSuccessCompressions() {
  yield* processSuccessCompressions(BatchCompressionApi.destroyCompressions)
}

function* processFailedCompressions(apiAction) {
  const { selectedFailedIds } = yield select(state => state.compression)
  yield call(apiAction, selectedFailedIds)
  yield put(emptyFailedCompressions())
}

export function* retryFailedCompressions() {
  yield* processFailedCompressions(BatchCompressionApi.retryCompressions)
}

export function* rejectFailedCompressions() {
  yield* processFailedCompressions(BatchCompressionApi.destroyCompressions)
}

export function* watchCompressionUpdates() {
  yield all([
    takeEvery(REFRESH_COMPRESSIONS_REQUEST, refreshCompressions),
    takeEvery(CREATE_COMPRESSIONS_REQUEST, createCompressions),
    takeEvery(APPLY_SUCCESS_COMPRESSIONS_REQUEST, applySuccessCompressions),
    takeEvery(REJECT_SUCCESS_COMPRESSIONS_REQUEST, rejectSuccessCompressions),
    takeEvery(RETRY_FAILED_COMPRESSIONS_REQUEST, retryFailedCompressions),
    takeEvery(REJECT_FAILED_COMPRESSIONS_REQUEST, rejectFailedCompressions)
  ])
}
